@import '../helpers/_mixins';

// m-btn
.m-btn {
  width: 90%;
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);

  a {
    @include font-size(14);
    position: relative;
    display: block;
    padding: 14px 20px;
    background: #fff;
    text-align: center; } }

// 01
.m-btn--01 a {
  border: 2px solid #000; }

.m-btn--01 a:hover {
  border-color: #249DE0; }

// cta
.m-btn--cta a {
  background: #e13a43;
  padding: 20px;
  color: #fff;

  &:before {
    content: none; } }

.m-btn--cta {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0); }

.active .m-btn {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
  transition: opacity 1s 1s $easeOutExpo, transform 1s 1s $easeOutExpo; }

.show-done .m-btn {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0); }

.active .m-btn--cta {
  transition: inital; }

@media screen and (min-width: 1024px) {
  .m-btn {

    a:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background: #249DE0;
      transition: width 0.5s $easeOutExpo; }

    a:hover:before {
      width: 100%;
      transition: width 0.5s $easeOutExpo; }

    a .txt {
      position: relative;
      z-index: 1;
      transition: color 0.5s $easeOutExpo; }

    a:hover .txt {
      color: #fff;
      transition: color 0.5s $easeOutExpo; } }

  .m-btn--cta a {
    &:before {
      content: none; } }

  .m-btn--cta a:hover {
    opacity: 0.7;
    transition: opacity 0.5s $easeOutExpo; }


  .active .m-btn {
    transition-delay: 1.2s, 1.2s; } }
