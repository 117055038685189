.u-pc {
	display: none; }

@media screen and (min-width: 1024px) {
	.u-sp {
		display: none !important; }

	.u-pc {
		display: block; } }

// font
.u-font-noto {
  font-family: 'Noto Sans JP'; }

.u-font-lulo-bold {
	font-family: "LuloCleanW01-OneBold"; }

.u-fw-bold {
	font-weight: 700; }

.u-color-white {
	color: #fff; }

.u-tac {
  text-align: center; }

.u-tdu {
  text-decoration: underline; }

.u-db {
  display: block; }
