@import "../../../../node_modules/sass-easing/_easings.scss";
@import '../helpers/_mixins';

.m-hdg {
  @include font-size(18);
  margin-bottom: 40px;
  text-align: center;

  .en {
    display: block;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px; }

  .en .txt {
    @include font-size(40);
    font-family: 'Work Sans Bold';
    line-height: 1.25;
    word-break: break-word;
    opacity: 0; }

  .ja .txt {
    line-height: 1.5;
    opacity: 0; }

  .ja {
    font-weight: 400;
    display: block; }

  .m-hdg__wrap {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; }

  .m-hdg__wrap .row {
    position: relative;
    line-height: 1; }

  // .row .mask
  //   width: 100%
  //   height: 100%
  //   // background: #000
  //   position: absolute
  //   left: 0
  //   top: 0

  .row .txt {
    position: relative;
    z-index: 2;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .8);
    color: #fff; } }

// variation
.m-hdg--black .row .txt {
  text-shadow: none;
  color: #000; }

.m-hdg--nols .en .txt {
  letter-spacing: 4px; }

.m-hdg--black01 .row .txt {
  text-shadow: none;
  color: #000; }

// active
.fp-item.active {
  // .en .mask
  //   animation: maskIn 0.4s 1s forwards $easeOutExpo

  .en .txt {
    // animation: textIn 1s 1.5s forwards $easeOutExpo
    opacity: 1;
    transition: opacity 1s $easeOutExpo; }

  // .ja .mask
  //   animation: maskIn 0.4s 1.1s forwards $easeOutExpo

  .ja .txt {
    opacity: 1;
    transition: opacity 1s $easeOutExpo;
 } }    // animation: textIn 1s 1.6s forwards $easeOutExpo

// 一回終わった
.show-done .m-hdg {
  .en .txt {
    opacity: 1; }

  .ja .txt {
    opacity: 1; } }

// sub
.m-sub-hdg {
  @include font-size(16);
  color: #fff;
  font-weight: 400;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px); }

// active
.active .m-sub-hdg {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
  transition-duration: 1.5s, 1.5s;
  transition-delay: 0.7s, 0.7s;
  transition-property: opacity, transform;
  transition-timing-function: $easeOutExpo; }

.show-done .m-sub-hdg {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0); }

@media screen and (min-width: 768px) {
  .m-hdg .en .txt {
    @include font-size(56);
    letter-spacing: 18px; }

  .m-hdg .m-hdg__wrap .txt {
    opacity: 0; }

  .m-hdg .m-hdg__wrap .row .mask {
    width: 0; }

  .fp-item.active {
    .en .txt {
      transition: opacity 1s 0.8s $easeOutExpo; }

    .ja .txt {
      transition: opacity 1s 0.8s $easeOutExpo; } }

  // active
  .active .m-sub-hdg {
    transition-delay: 1s, 1s; }

  // variation
  .m-hdg--nols .en .txt {
    letter-spacing: 4px; }

  .m-hdg--black01 .row .txt {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .8);
    color: #fff; } }
