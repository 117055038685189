@import '../helpers/_mixins';
@import "../../../../node_modules/sass-easing/_easings.scss";

// p-lower-content
.p-lower-content {
  transition: transform 0.75s $easeOutExpo;
  padding-bottom: 60px;

  p {
    line-height: 1.75; } }

// l-head
.l-head {
  padding: 40px 20px;

  .l-head__hdg {
    .en {
      text-transform: capitalize;
      font-family: 'Work Sans Bold';
      letter-spacing: 2px; }

    .ja {
      @include font-size(14);
      display: block; } } }

@media screen and (min-width: 1024px) {

  // haed
  .l-head {
    padding: 40px 20px;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;

    .l-head__hdg {
      .en {
        @include font-size(40); }

      .ja {
        @include font-size(16);
        display: inline-block;
        padding-left: 1em; } } }

  .p-lower-content {
    padding-top: 80px; } }



// l-wrap
.l-wrap {
  width: 100%; }

@media screen and (min-width: 1024px) {
  .l-wrap {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto; } }
