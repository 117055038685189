// fullpagejs 関連
.fp-viewing-0 #fp-nav {
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s $easeOutExpo; }

.fp-viewing-1 #fp-nav,
.fp-viewing-3 #fp-nav,
.fp-viewing-4 #fp-nav,
.fp-viewing-6 #fp-nav,
.fp-viewing-8 #fp-nav,
.fp-viewing-10 #fp-nav {
  background: #000;
  border-radius: 10px;

  ul li .fp-tooltip {
    color: #000; }

  ul li .fp-tooltip.fp-right {
    right: 40px; } }


#fp-nav ul li, .fp-slidesNav ul li {
  width: 18px;
  height: 24px; }

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  width: 6px;
  height: 6px;
  background: #fff; }

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  width: 6px;
  height: 6px;
  margin: -2px 0 0 -2px; }

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
  margin: -6px 0 0 -5px; }


#fp-nav ul li .fp-tooltip.fp-right {
  right: 30px; }

#fp-nav ul li .fp-tooltip {
  top: 4px;
  transform: translateX(-5px);
  transition: transform 1s $easeOutExpo; }

#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active+.fp-tooltip {
  transform: translateX(0);
  transition: transform 1.5s $easeOutExpo; }

#fp-nav ul li a:before,
.fp-slidesNav ul li a:before,
#fp-nav ul li a:after,
.fp-slidesNav ul li a:after {
  content: '';
  opacity: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  margin: -2px 0 0 -2px;
  background: #fff;
  z-index: 2; }

#fp-nav ul li:hover a:before,
.fp-slidesNav ul li:hover a:before {
  animation-name: Spreading;
  animation-duration: 1.5s;
  animation-timing-function: $easeOutExpo; }

#fp-nav ul li:hover a:after,
.fp-slidesNav ul li a:after, {
  animation-name: Contraction;
  animation-duration: 1.5s;
  animation-timing-function: $easeOutExpo; }
