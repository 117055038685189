@font-face {
  font-family: "Work Sans Bold";
  src: url("/assets/fonts/WorkSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "Work Sans Medium";
  src: url("/assets/fonts/WorkSans-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "Work Sans";
  src: url("/assets/fonts/WorkSans-Regular.ttf") format("truetype"); }
