@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot?qtxtw0');
  src:  url('/assets/fonts/icomoon.eot?qtxtw0#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?qtxtw0') format('truetype'),
    url('/assets/fonts/icomoon.woff?qtxtw0') format('woff'),
    url('/assets/fonts/icomoon.svg?qtxtw0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play2:before {
  content: "\ea15";
}
.icon-play3:before {
  content: "\ea1c";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
