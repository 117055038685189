// @keyframes maskIn
//   0%
//     width: 0
//   100%
//     width: 100%

// @keyframes textIn
//   0%
//     opacity: 0
//   100%
//     opacity: 1

@keyframes dotAnim {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes Contraction {
  0% {
    transform: scale(3);
    opacity: 0; }

  99% {
    opacity: 1; }

  100% {
    transform: scale(1);
    opacity: 0; } }

@keyframes Spreading {
  0% {
    opacity: 1; }

  100% {
    transform: scale(3);
    opacity: 0; } }

@keyframes scroll-line {
  0% {
    height: 100px; }

  100% {
    height: 0; } }

@keyframes lineInOut {
  0% {
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top; }

  5% {
    opacity: 1;
    transform: scaleY(0);
    -webkit-transform-origin: top;
    transform-origin: top; }

  59% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: top; }

  60% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: bottom; }

  to {
    opacity: 1;
    transform: scaleY(0);
    transform-origin: bottom; } }


@keyframes lineInOut2 {
  0% {
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top; }


  5% {
    opacity: 1;
    transform: scaleY(0);
    transform-origin: top; }


  20% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: top; }


  21% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: bottom; }

  to {
    opacity: 1;
    transform: scaleY(0);
    transform-origin: bottom; } }
