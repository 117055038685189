@import '../_fundamentals';
@import '../../../../node_modules/swiper/css/swiper.css';
@import '../../css/lightbox.min.css';
@import '../../css/iconfont.css';
@import "../../../../node_modules/sass-easing/_easings.scss";
@import "../../../../node_modules/fullpage.js/dist/fullpage.min.css";

.p-content {
  position: relative;
  transition: transform 0.75s $easeOutExpo; }

// ------------------------------------------
// MV ---------------------------------------
.p-mv {
  height: 100vh;
  width: 100%; }

.p-mv {
  position: relative; }

.p-mv__body {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3); }

.p-mv__skip {
  position: absolute;
  right: 2%;
  bottom: 2%;
  cursor: pointer;
  padding: 0.5rem 2rem;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  @include font-size(12);
  transition: background 0.4s $easeOutExpo, color 0.4s $easeOutExpo; }

.p-mv__desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%; }

.p-mv__scroller {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 1px;
  height: 50px;
  background: #fff;
  z-index: 4;
  transform-origin: 50% 100%;

  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    animation-duration: 1.6s;
    transform: scaleY(0);
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    display: block; }

  &:before {
    position: absolute;
    background: #dcdcdc;
    top: 0;
    z-index: 5;
    animation-name: lineInOut2; }

  &::after {
    background: #787878;
    animation-name: lineInOut;
    z-index: 4; } }

.p-mv__maintxt {
  color: $baseColor01; }

.p-mv__bg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("/assets/images/top/bg_video_sp01.jpg") no-repeat 50% 50%;
  background-size: cover; }

.p-mv__bg .bg-body {
  position: relative;
  width: 100%;
  height: 100%; }

.p-mv__bg .bg-video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  object-fit: cover; }

.p-mv__text {
  @include font-size(30);
  color: #fff;
  text-align: center; }

.p-mv__play-btn {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 1px solid $baseColor01;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  .icon-play3 {
    @include font-size(28);
    color: #fff; } }

.p-mv__modal {
  position: absolute;
  width: 100%;
  height: calc(100vh - 60px);
  top: 60px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: none;

  .modal-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 12; }

  .modal-body {
    width: 80%;
    height: 70vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 13; }

  .modal-content {
    position: relative;
    z-index: 12;
    width: 100%;
    height: 100%; }

  .modal-wrap {
    height: 100%; }

  .modal-video {
    width: 100%;
    height: inherit;
    object-fit: cover; }

  .modal-close {
    position: absolute;
    right: 10px;
    top: 40px;
    z-index: 13;
    width: 20px;
    height: 20px; }

  .modal-close::before,
  .modal-close::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 30px;
    background: #fff;
    border-radius: 4px; } }

.modal-close::before {
  transform: translate(-50%,-50%) rotate(45deg); }


.modal-close::after {
  transform: translate(-50%,-50%) rotate(-45deg); }

// js
.p-mv__bg .bg-body.is-clicked {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s $easeOutExpo; }

.p-mv__skip.is-clicked {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s $easeOutExpo; }

@media screen and (min-width: 1024px) {
  .p-mv__bg {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("/assets/images/top/bg_video01.jpg"); }

  .p-mv__desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    display: none; }

  .p-mv__skip:hover {
    background-color: #fff;
    color: #000; }

  .p-mv__body {
    background: transparent; }

  .p-mv__bg .bg-video {
    width: auto; }

  .p-mv__bg .bg-body:before {
    content: none; }

  .p-mv__body .image {
    display: none; }

  .p-mv__text {
    @include font-size(54); }

  .p-mv__scroller {
    height: 100px; } }

// ------------------------------------------
// Section
// -----------------------------------------

.p-section {
  position: relative;
  padding: 80px 20px 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0;
  z-index: 2;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: -1; } }

.p-section--type01 {
  &::before {
    content: none; } }

@media screen and (min-width: 1024px) {
  .p-section {
    padding-bottom: 0;
    padding-top: 0; } }

// ------------------------------------------
// Style
// ------------------------------------------

.p-style__item {
  position: relative;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px); }

.p-style__item .image img {
  width: 100%; }

.p-style__item:first-child {
  margin-top: 0; }

// .p-style__item--02 .image:before
//   background-image: url('/assets/images/top/style02.jpg')

.p-style__item--03 .image:before {
  background-image: url('/assets/images/top/style03.jpg');
  background-position: 50% 0; }

// .p-style__item--04 .image:before
//   background-image: url('/assets/images/top/style04.jpg')

.p-style__item--01 .texts {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 20px 10px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;

  .en {
    @include font-size(20);
    display: block;
    text-transform: uppercase;
    font-weight: 400; }

  .ja {
    @include font-size(14); } }


// 02
// .p-style__item--02 .texts
//   position: absolute
//   left: 0
//   bottom: 0
//   width: 100%
//   padding: 20px 20px 10px
//   background: rgba(0, 0, 0, 0.5)
//   color: #fff
//   text-align: center

//   .en
//     @include font-size(20)
//     display: block
//     text-transform: uppercase
//     font-weight: 400

//   .ja
//     @include font-size(14)

// .p-style__item--02 .image:after
//   content: ""
//   display: block
//   position: absolute
//   left: 0
//   top: 0
//   width: 100%
//   height: 100%
//   background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(255,255,255,0) 100%)

// .p-style__item--02 .texts
//   background: transparent

// 03
.p-style__item--03 .texts {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px 20px 10px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;

  .en {
    @include font-size(14);
    display: block;
    text-transform: uppercase;
    font-weight: 400; }

  .ja {
    @include font-size(14); } }

.p-style__item--03 .image {
  position: relative; }

.p-style__item--03 .texts {
  position: relative;
  left: auto;
  bottom: auto;
  background: #fff;
  color: $baseColor02;
  padding: 10px;
  border: 1px solid #eee; }

// 04
// .p-style__item--04 .image
//   position: relative

// .p-style__item--04 .image:after
//   content: ''
//   display: block
//   position: absolute
//   left: 0
//   top: 0
//   width: 100%
//   height: 100%
//   opacity: 0.7
//   background: rgb(0, 0, 0)

// .p-style__item--04 .texts
//   display: flex
//   align-items: center
//   flex-direction: column
//   justify-content: center
//   position: absolute
//   color: #fff
//   left: 0
//   top: 0
//   right: 0
//   bottom: 0

//   .en
//     @include font-size(20)
//     display: block
//     text-transform: uppercase
//     font-weight: 400

//   .ja
//     @include font-size(14)

.active .p-style__item {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
  transition-duration: 1.5s, 0.5s;
  transition-timing-function: $easeOutExpo;
  transition-property: opacity, transform; }

.active .p-style__item:nth-child(1) {
  transition-delay: .3s; }

.active .p-style__item:nth-child(2) {
  transition-delay: .4s; }

.active .p-style__item:nth-child(3) {
  transition-delay: .5s; }

.active .p-style__item:nth-child(4) {
  transition-delay: .6s; }

.show-done .p-style__item {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0); }


@media screen and (min-width: 769px) {
  .p-style .fp-tableCell {
    padding-left: 2%;
    padding-right: 2%; }

  .p-style__list {
    display: flex;
    align-items: center; }

  .p-style__item {
    width: 25%;
    margin: 0 10px; }

  // 01
  // .p-style__item--01 a

  //   .image img
  //     width: 100%
  //     transition: transform 4s $easeOutExpo

  //   &:hover
  //     .image img
  //       transform: scale(1.1)
  //       transition: transform 4s $easeOutExpo

  // .p-style__item--01 .texts
  //   padding: 10px

  // .p-style__item--01 .texts .en
  //   @include font-size(18)

  // .p-style__item--01 .texts .ja
  //   @include font-size(12)

  // 02
  // .p-style__item--02 a

  //   .image img
  //     width: 100%
  //     transition: transform 4s $easeOutExpo

  //   &:hover
  //     .image img
  //       transform: scale(1.1)
  //       transition: transform 4s $easeOutExpo

  // .p-style__item--02 .texts
  //   padding: 10px

  // .p-style__item--02 .texts .en
  //   @include font-size(18)

  // .p-style__item--02 .texts .ja
  //   @include font-size(12)

  // 03
  .p-style__item--03 a {
    display: block;
    position: relative;

    .texts .en {
      @include font-size(16); }

    img {
      transition: transform 1s $easeOutExpo; }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 0px solid #249de0;
      transition: border 1s $easeOutExpo; }

    &:hover img {
      transform: scale(1.05);
      transition: transform 1s $easeOutExpo; }

    &:hover::after {
      border-width: 6px;
      transition: border 1s $easeOutExpo; } }

  // 04
  // .p-style__item--04 a
  //   display: block
  //   position: relative

  //   .image img
  //     transition: fileter 1s $easeOutExpo

  //   .image::after
  //     transition: opacity 1s $easeOutExpo

  //   &:hover .image img
  //     filter: blur(2px)
  //     transition: fileter 1s $easeOutExpo

  //   &:hover .image::after
  //     opacity: 0.2
 }  //     transition: opacity 1s $easeOutExpo

@media screen and (min-width: 1024px) {
  .active .p-style__item {
    transition-duration: 1.5s, 1.5s; }

  .active .p-style__item:nth-child(1) {
    transition-delay: 1.2s; }

  .active .p-style__item:nth-child(2) {
    transition-delay: 1.3s; }

  .active .p-style__item:nth-child(3) {
    transition-delay: 1.4s; }

  .active .p-style__item:nth-child(4) {
    transition-delay: 1.5s; } }

@media screen and (min-width: 1400px) {
  .p-style .fp-tableCell {
    padding-left: 10%;
    padding-right: 10%; } }

// ------------------------------------------
// About
// ------------------------------------------

.p-about {
  background-image: url('/assets/images/top/bg_about01.jpg'); }

// ------------------------------------------
// Instructor
// ------------------------------------------

.p-instructors {
  background-color: #fff; }

// ------------------------------------------
// Class
// ------------------------------------------

.p-class__hdg {
  @include font-size(18);
  color: #333;
  border-bottom: 2px solid #333;
  padding-bottom: 6px; }

.p-class__box {
  margin-top: 20px;
  padding: 20px;
  background: #fff;
  border: 4px solid #333;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);

  &:first-child {
    margin-top: 0; } }

.p-class__list {
  @include font-size(14);
  color: #333;
  margin-top: 20px;
  padding-left: 1em;
  text-indent: -1em;
  line-height: 1.75; }

.p-class__list + .p-class__definition,
.p-class__hdg + .p-class__definition {
  margin-top: 20px; }

.p-class__definition {
  display: flex;
  color: #333;

  dt,dd {
    @include font-size(14);
    padding: 10px; }

  dt {
    width: 40%;
    max-width: 170px;
    background: #F5F5F5; }
  dd {
    width: 60%; } }

.p-class__table {
  @include font-size(14);
  color: #333;

  th, td {
    padding: 10px 0; }

  th {
    width: 40%;
    padding-right: 10px;
    text-align: left; } }

.p-class__txt {
  @include font-size(14);
  margin-top: 10px;
  color: #fff;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px); }

.active.p-class .p-class__txt {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
  transition-duration: 1.5s, 1.5s;
  transition-property: opacity, transform;
  transition-timing-function: $easeOutExpo;
  transition-delay: 1.3s; }

.active.p-class .p-class__box {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
  transition-duration: 1s, 1s;
  transition-property: opacity, transform;
  transition-timing-function: $easeOutExpo; }

.active.p-class .p-class__box:nth-child(1) {
  transition-delay: 1.1s; }

.active.p-class .p-class__box:nth-child(2) {
  transition-delay: 1.2s; }

.show-done.p-class .p-class__box {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0); }

.show-done.p-class .p-class__txt {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0); }

@media screen and (min-width: 1024px) {
  .p-class {
    background-image: url('/assets/images/top/bg_class01.jpg');

    &:before {
      background: rgba(0, 0, 0, 0.3); } }

  .p-class .fp-tableCell {
    padding-top: 60px; }

  .p-class .m-hdg {
    margin-bottom: 20px; }

  .p-class {
    padding-right: 10%;
    padding-left: 10%; }

  .p-class .p-class__definition dd {
    width: 100%; }

  .p-class .p-class__body {
    display: flex;
    justify-content: space-between; }

  .p-class .p-class__box {
    width: 49%;
    padding: 10px;
    margin-top: 10px; }

  .active.p-class .p-class__txt {
    transition-delay: 1.1s; }

  .active.p-class .p-class__box:nth-child(1) {
    transition-delay: 1s; }

  .active.p-class .p-class__box:nth-child(2) {
    transition-delay: 1.1s; } }

@media screen and (min-width: 1400px) {
  .p-class {
    padding-right: 20%;
    padding-left: 20%; } }


// ------------------------------------------
// Entertainment
// ------------------------------------------
.p-entertainment {
  padding-top: 60px;
  background: #fff; }

.p-entertainment__item {
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  overflow: hidden; }

.p-entertainment__list a {
  display: block;
  padding: 40px 20px;
  color: #fff;

  .texts {
    position: relative;
    z-index: 2; }

  .texts .en {
    @include font-size(24);
    display: block;
    font-family: 'LuloCleanW01-OneBold';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .7);
    text-transform: uppercase; }

  .texts .ja {
    @include font-size(16);
    letter-spacing: 4px;
    margin-top: 5px;
    display: block;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .7); } }

.p-entertainment__item a {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }

.p-entertainment__item--01 a {
  position: relative;
  background-image: url('/assets/images/top/bg_entertainment01.jpg');

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 0;
    pointer-events: none; }

  a {
    position: relative;
    z-index: 2; } }

.p-entertainment__item--02 a {
  background-image: url('/assets/images/top/bg_entertainment02.jpg'); }

.p-entertainment__item--03 a {
  background-image: url('/assets/images/top/bg_entertainment03.jpg'); }

.p-entertainment__item--04 a {
  background-image: url('/assets/images/top/bg_entertainment04.jpg');
  background-position: 10% 50%; }

.p-entertainment__item--05 a {
  background-image: url('/assets/images/top/bg_entertainment06.jpg');
  background-position: 60% 50%; }

.p-entertainment__item--06 a {
  background-image: url('/assets/images/top/bg_entertainment05.jpg'); }

.active .p-entertainment__item {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
  transition-duration: 1.5s, 1.5s;
  transition-property: opacity, transform;
  transition-timing-function: $easeOutExpo; }

.active .p-entertainment__item--01 {
  transition-delay: 1s; }

.active .p-entertainment__item--02 {
  transition-delay: 1.1s; }

.active .p-entertainment__item--03 {
  transition-delay: 1.2s; }

.active .p-entertainment__item--04 {
  transition-delay: 1.3s; }

.active .p-entertainment__item--05 {
  transition-delay: 1.4s; }

.active .p-entertainment__item--06 {
  transition-delay: 1.5s; }

.show-done .p-entertainment__item {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0); }


@media screen and (min-width: 1024px) {
  .p-entertainment__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -20px; }

  .p-entertainment__item {
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
    overflow: hidden;
    width: 32%;
    margin-top: 20px; }

  .p-entertainment__item a {
    display: flex;
    align-items: flex-end;
    padding: 10px;

    &:after {
     content: '';
     display: block;
     padding-top: 30%; }


    .texts .en {
      @include font-size(18); }

    .texts .ja {
      @include font-size(14); } }

  .p-entertainment__item a:hover {
    opacity: 0.7;
    transition: opacity 1s $easeOutExpo; }

  .active .p-entertainment__item--01 {
    transition-delay: 1.2s; }

  .active .p-entertainment__item--02 {
    transition-delay: 1.3s; }

  .active .p-entertainment__item--03 {
    transition-delay: 1.4s; }

  .active .p-entertainment__item--04 {
    transition-delay: 1.5s; }

  .active .p-entertainment__item--05 {
    transition-delay: 1.6s; }

  .active .p-entertainment__item--06 {
    transition-delay: 1.7s; } }


// ------------------------------------------
// Blog
// ------------------------------------------
.p-blog {
  position: relative;
  background: url('/assets/images/top/bg_shop_sp01.jpg') no-repeat 50% 50%;
  background-size: cover; }

@media screen and (min-width: 768px) {

  .p-blog {
    background-position: 50% 100%;
    background-image: url('/assets/images/top/bg_shop01.jpg'); }

  .p-blog__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }

  .p-blog__item .m-btn {
    width: 300px;
    margin-right: 20px;
    margin-left: 20px; } }

// ------------------------------------------
// Amrita
// ------------------------------------------
.p-amrita {
  position: relative;
  background-image: url('/assets/images/top/bg_amrita_sp01.jpg');
  background-position: 50% 50%;

  &:before {
    background: rgba(0, 0, 0, 0.3); }

  .m-hdg .m-hdg__wrap .ja.row {
    position: relative;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0; }

    &:before {
      background-image: url('/assets/images/top/icon_amrita01.png');
      left: -50px; }

    &:after {
      background-image: url('/assets/images/top/icon_amrita02.jpg');
      right: -50px; } } }


.active.p-amrita .m-hdg .m-hdg__wrap .ja.row {
  &:before,
  &:after, {
    opacity: 1;
    transition: opacity 1s 0.5s $easeOutExpo; } }

@media screen and (min-width: 1024px) {
  .p-amrita {
    background-image: url('/assets/images/top/bg_amrita01.jpg'); }

  .active.p-amrita .m-hdg .m-hdg__wrap .ja.row {
    &:before,
    &:after, {
      transition-delay: 2s; } } }



// ------------------------------------------
// Gallery
// ------------------------------------------

.p-gallery {
  position: relative;
  background-image: url('/assets/images/top/bg_gallery01.jpg');

  &:before {
    background: rgba(0, 0, 0, 0.3); } }

.p-gallery__body {
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px); }

.p-gallery__item {
  position: relative;
  width: calc(50% - 20px);
  margin: 10px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  a {
    display: block;
    padding-top: 100%; }

  img {
    display: none; } }


.p-gallery__item--01 {
  background-image: url('/assets/images/common/gallery/gallery01.jpg'); }
.p-gallery__item--02 {
  background-image: url('/assets/images/common/gallery/gallery02.jpg'); }
.p-gallery__item--03 {
  background-image: url('/assets/images/common/gallery/gallery03.jpg'); }
.p-gallery__item--04 {
  background-image: url('/assets/images/common/gallery/gallery04.jpg'); }
.p-gallery__item--05 {
  background-image: url('/assets/images/common/gallery/gallery05.jpg'); }
.p-gallery__item--06 {
  background-image: url('/assets/images/common/gallery/gallery06.jpg'); }
.p-gallery__item--07 {
  background-image: url('/assets/images/common/gallery/gallery07.jpg'); }
.p-gallery__item--08 {
  background-image: url('/assets/images/common/gallery/gallery08.jpg'); }
.p-gallery__item--09 {
  background-image: url('/assets/images/common/gallery/gallery09.jpg'); }
.p-gallery__item--10 {
  background-image: url('/assets/images/common/gallery/gallery10.jpg'); }
.p-gallery__item--11 {
  background-image: url('/assets/images/common/gallery/gallery11.jpg'); }
.p-gallery__item--12 {
  background-image: url('/assets/images/common/gallery/gallery12.jpg'); }
.p-gallery__item--13 {
  background-image: url('/assets/images/common/gallery/gallery13.jpg'); }
.p-gallery__item--14 {
  background-image: url('/assets/images/common/gallery/gallery14.jpg'); }
.p-gallery__item--15 {
  background-image: url('/assets/images/common/gallery/gallery15.jpg'); }
.p-gallery__item--16 {
  background-image: url('/assets/images/common/gallery/gallery16.jpg'); }
.p-gallery__item--17 {
  background-image: url('/assets/images/common/gallery/gallery17.jpg'); }
.p-gallery__item--18 {
  background-image: url('/assets/images/common/gallery/gallery18.jpg'); }
.p-gallery__item--19 {
  background-image: url('/assets/images/common/gallery/gallery19.jpg'); }
.p-gallery__item--20 {
  background-image: url('/assets/images/common/gallery/gallery20.jpg'); }
.p-gallery__item--21 {
  background-image: url('/assets/images/common/gallery/gallery21.jpg'); }
.p-gallery__item--22 {
  background-image: url('/assets/images/common/gallery/gallery22.jpg'); }
.p-gallery__item--23 {
  background-image: url('/assets/images/common/gallery/gallery23.jpg'); }
.p-gallery__item--24 {
  background-image: url('/assets/images/common/gallery/gallery24.jpg'); }
.p-gallery__item--25 {
  background-image: url('/assets/images/common/gallery/gallery25.jpg'); }
.p-gallery__item--26 {
  background-image: url('/assets/images/common/gallery/gallery26.jpg'); }
.p-gallery__item--27 {
  background-image: url('/assets/images/common/gallery/gallery27.jpg'); }
.p-gallery__item--28 {
  background-image: url('/assets/images/common/gallery/gallery28.jpg'); }


.active .p-gallery__body {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
  transition-duration: 1.5s;
  transition-property: opacity;
  transition-timing-function: $easeOutExpo;
  transition-delay: 1s; }

.show-done .p-gallery__body {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0); }

#lightboxOverlay {
  position: fixed !important;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important; }

#lightbox {
  position: fixed !important;
  top: 50% !important;
  transform: translateY(-50%); }

.lb-nav a.lb-next {
  background-image: url('/assets/images/top/next.png'); }

.lb-nav a.lb-prev {
  background-image: url('/assets/images/top/prev.png'); }

.lb-cancel {
  background-image: none; }

.lb-data .lb-close {
  background-image: url('/assets/images/top/close.png'); }

.p-gallery .gallery-slider {
  padding-bottom: 40px; }

.p-gallery .swiper-button-next:after,
.p-gallery .swiper-container-rtl .swiper-button-prev:after,
.p-gallery .swiper-button-prev:after,
.p-gallery .swiper-container-rtl .swiper-button-next:after {
  color: #fff; }

.p-gallery .swiper-button-prev,
.p-gallery .swiper-container-rtl .swiper-button-next {
  right: 70px;
  left: auto; }

.p-gallery .swiper-button-next,
.p-gallery .swiper-button-prev {
  outline: none; }

.p-gallery .swiper-button-next:after,
.p-gallery .swiper-button-prev:after {
  font-size: 30px; }

.p-gallery .buttons-wrap {
  position: absolute;
  bottom: 20px;
  right: 0;
  opacity: 0;
  transform: translateY(10px);
  pointer-events: none; }

.active.p-gallery .buttons-wrap {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
  transition-duration: 1.5s;
  transition-property: opacity;
  transition-timing-function: $easeOutExpo;
  transition-delay: 1s; }

.show-done.p-gallery .buttons-wrap {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0); }

@media screen and (min-width: 768px) {
  .p-gallery__item {
    width: calc(33.3% - 20px); } }

@media screen and (min-width: 1024px) {
  .p-gallery .m-hdg {
    margin-bottom: 20px; }

  .p-gallery .fp-tableCell {
    padding-top: 60px; }

  .p-gallery__body {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px; }

  .p-gallery__item {
    width: calc(150px - 10px);
    margin: 5px; }

  .p-gallery .buttons-wrap {
    right: auto;
    left: 50%;
    transform: translateX(-50%); }

  .p-gallery .gallery-slider {
    padding-bottom: 50px; }

  .p-gallery .swiper-button-prev,
  .p-gallery .swiper-container-rtl .swiper-button-next {
    right: 10px; }

  .p-gallery .swiper-button-next,
  .p-gallery .swiper-container-rtl .swiper-button-prev {
    right: -40px; }

  .active .p-gallery__body {
    transition-delay: 1.2s; }

  .active.p-gallery .buttons-wrap {
    transition-delay: 1.2s; } }

@media screen and (min-width: 1400px) and (min-height: 800px) {
  .p-gallery__body {
    max-width: 960px; }

  .p-gallery__item {
    width: calc(25% - 10px); }

  .active .p-gallery__body {
    transition-delay: 1.2s; } }




// ------------------------------------------
// News
// ------------------------------------------

.p-news {
  padding-bottom: 20px; }

.p-news .p-misc__news {
  padding-bottom: 60px; }

.p-news .news-list {
  @include font-size(14);
  margin-top: 20px; }

.p-news .news-item {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &:first-child {
    margin-top: 0; } }

.p-news .news-item a {
  text-decoration: underline; }

.p-news .news-time {
  display: inline-block;
  font-weight: 700;
  padding-right: 1rem; }

.p-news .news-texts {
  width: 100%;
  margin-top: 0.5rem; }

.p-news .news-cate {
  @include font-size(12);
  font-family: 'Noto Sans JP';
  background: #2B00C4;
  color: #fff;
  display: inline-block;
  padding: 4px 4px;
  text-transform: uppercase;
  line-height: 1;
  min-width: 4em;
  text-align: center;
  margin-right: 10px;

  &.info {
    background: #EC088F; }

  &.kirtan {
    background: #55008A; } }

@media screen and (min-width: 1024px) {
  .p-news .news-list {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
    height: 46%; } }



// ------------------------------------------
// Location
// ------------------------------------------

.p-location {
  position: relative;
  padding-top: 20px;

  .m-hdg .row .txt {
    @include font-size(22); }

  .location-hdg {
    .en {
      @include font-size(28);
      font-family: 'Work Sans Bold';
      text-transform: uppercase;
      display: block;
      letter-spacing: 4px; }

    .ja {
      @include font-size(18);
      margin-top: 5px; } }

  .p-location__wrap {
    position: relative; }

  .location-item {
    padding-bottom: 60px; }

  .buttons-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row-reverse;
    width: 100px;
    height: 50px;
    z-index: 9;
    border: 1px solid #000; }

  .swiper-button-next,
  .swiper-button-prev {
    position: relative;
    width: 50px;
    height: 50px;
    transform: translateY(-50%);
    margin-top: 0;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

  .swiper-button-next {
    right: 0; }

  .swiper-button-prev {
    left: 0;
    border-right: 1px solid #000; }

  .swiper-button-next:after, .swiper-button-prev:after {
    @include font-size(16);
    content: "";
    background: url('/assets/images/common/img_slide_arrow.svg') no-repeat 50% 50%;
    background-size: contain;
    width: 16px;
    height: 12px; }

  .swiper-button-prev:after {
    transform: rotate(180deg); }

  .swiper-pagination {
    bottom: 20px;
    width: auto; }

  .swiper-pagination .swiper-pagination-bullet {
    margin: 0 4px; }

  .place-text {
    display: none; }

  .map-indicator .map-indicator__map {
    display: none; }

  .note-text {
    display: none; } }

@media screen and (min-width: 1024px) {
  .p-location .m-hdg {
    margin-top: 20px;
    margin-bottom: 20px; }

  .p-location .m-hdg .ja .txt {
    @include font-size(32); }

  .p-location .p-location__wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3vh; }

  .p-location .map-indicator {
    position: relative;
    width: 34%; }

  .p-location .map-indicator .map-indicator__map {
    display: block; }

  .p-location .map-indicator__map {
    width: 100%; }

  .p-location .map-indicator__map .japan-map {
    fill: #004e00; }

  .p-location .swiper-container {
    padding-bottom: 40px;
    margin-left: initial;
    margin-right: auto;
    width: 56%;
    position: static;
    overflow: initial; }

  .p-location .location-item {
    padding-bottom: 0; }

  .p-location .location-hdg {
    text-align: center; }

  .p-location .buttons-wrap {
    width: 120px;
    height: 60px; }

  .p-location .swiper-button-next,
  .p-location .swiper-button-prev {
    width: 60px;
    height: 60px; }

  .p-location .buttons-wrap .swiper-button-prev:after,
  .p-location .buttons-wrap .swiper-button-next:after {
    transition: transform 0.4s $easeOutExpo; }

  .p-location .swiper-pagination {
    position: absolute;
    bottom: auto;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }

  .p-location .swiper-pagination .swiper-pagination-bullet {
    position: absolute;
    background-color: #e13a43;
    opacity: 1;
    width: 0.5vw;
    height: 0.5vw; }

  .p-location .swiper-pagination .swiper-pagination-bullet:nth-child(1) {
    left: 61.45%;
    top: 69.5%; }

  .p-location .swiper-pagination .swiper-pagination-bullet:nth-child(2) {
    left: 64.2%;
    top: 67.5%; }

  .p-location .swiper-pagination .swiper-pagination-bullet:nth-child(3) {
    left: 39.7%;
    top: 75.2%; }

  .p-location .swiper-pagination .swiper-pagination-bullet:nth-child(4) {
    left: 69%;
    top: 52.25%; }

  .p-location .swiper-pagination .swiper-pagination-bullet:nth-child(5) {
    left: 72.5%;
    top: 18.5%; }

  .p-location .swiper-pagination .swiper-pagination-bullet-active {
    cursor: initial;
    background-color: #fff; }

  .p-location .swiper-pagination .swiper-pagination-bullet-active:before,
  .p-location .swiper-pagination .swiper-pagination-bullet-active:after {
    content: '';
    opacity: 0;
    margin: auto;
    width: 0.5vw;
    height: 0.5vw;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    margin: -0.25vw 0 0 -0.25vw;
    background: #fff;
    z-index: 2; }

  .p-location .swiper-pagination .swiper-pagination-bullet-active:before {
    animation-name: Spreading;
    animation-duration: 1.5s;
    animation-timing-function: $easeOutExpo;
    animation-iteration-count: infinite; }

  .p-location .swiper-pagination .swiper-pagination-bullet-active:after {
    animation-name: Contraction;
    animation-duration: 1.5s;
    animation-timing-function: $easeOutExpo;
    animation-iteration-count: infinite; }

  .p-location .place-text {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

  .p-location .place-text > span {
    font-size: clamp(0.8rem, 0.8vw, 1.4rem);
    font-family: 'Noto Sans JP';
    position: absolute;
    text-transform: uppercase;
    font-weight: 700;
    color: #000;
    line-height: 1;
    letter-spacing: 0.1rem;
    text-shadow: 1px 1px 0 #FFF, -1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px -1px 0 #FFF, 0px 1px 0 #FFF,  0-1px 0 #FFF, -1px 0 0 #FFF, 1px 0 0 #FFF; }

  .p-location .place-text > .yokohama {
    left: 65%;
    top: 69.8%; }

  .p-location .place-text > .setagaya {
    left: 68%;
    top: 66.3%; }

  .p-location .place-text > .osaka {
    left: 43%;
    top: 74.4%; }

  .p-location .place-text > .sendai {
    left: 72.5%;
    top: 51.7%; }

  .p-location .place-text > .sapporo {
    right: 28%;
    top: 18%; }

  .p-location .note-text {
    @include font-size(14);
    display: block;
    position: absolute;
    left: 0;
    bottom: -6vh; }

  .p-location .swiper-container .m-media {
    width: 100%; }

  // hover
  .p-location .buttons-wrap .swiper-button-prev:hover:after {
    transform: translateX(-4px) rotate(180deg); }

  .p-location .buttons-wrap .swiper-button-next:hover:after {
    transform: translateX(4px); } }

// loader
.p-loader {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;

  .loader__body {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
 }    // flex-direction: column

  .loader__body .img-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-image: url('/assets/images/common/logo.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 200px 126px; }


  .loader__body .img-wrap__item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  .loader__body .p-loader__logo {
    width: 50%;
    text-align: center; }

  .loader__body .anim {
    width: 100%;
    text-align: center; }

  // .loader__body .txt
  //   @include font-size(72)
  //   font-family: Helvetica
  //   margin: 10px auto 0
  //   text-align: center
  //   white-space: nowrap

  .loader__body .hw-wrap {
    width: 100%;
    height: 100%; }

  .loader__body .hw-body {
    width: 100%;
    height: 100%; }

  .loader__body .anim--01 {
    transition: opacity 0.4s $easeOutExpo; }

  .loader__body .anim--02 {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 20px; }

  .loader__body .m-mask.m-mask--01 {
    fill: none;
    stroke: #fff;
    stroke-width: 8px;
    stroke-linecap: round;
    stroke-miterlimit: 10; }

  .loader__reveal-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }

  .loader__reveal-item--01 {
    background: #fff;
    transform: translateX(-100%); }
  .loader__reveal-item--02 {
    background: #eee;
    transform: translateX(-100%); }
  .loader__reveal-item--03 {
    background: #ddd;
    transform: translateX(-100%); }
  // .loader__reveal-item--04
  //   background: #52abf9
  //   transform: translateX(-100%)
  // .loader__reveal-item--05
  //   background: #4a8fe7
  //   transform: translateX(-100%)
  // .loader__reveal-item--06
  //   background: #8bb7ef
  //   transform: translateX(-100%)
  // .loader__reveal-item--07
  //   background: #bdd6f6
 }  //   transform: translateX(-100%)

.loading-done {
  .p-loader {
    transform: translateX(100%);
    transition: transform 1s $easeInOutCubic; }

  .loader__reveal-item {
    transform: translateX(0);
    transition: transform 0.75s 0s $easeInOutCubic;

    &--01 {
      transition-delay: 0s; }
    &--02 {
      transition-delay: 0.1s; }
    &--03 {
      transition-delay: 0.2s; }
    // &--04
    //   transition-delay: 0.4s
    // &--05
    //   transition-delay: 0.5s
    // &--06
    //   transition-delay: 0.6s
    // &--07
 }    //   transition-delay: 0.7s

  .loader__body .anim--01,
  .loader__body .anim--02, {
    opacity: 0;
    transition: opacity 0.2s $easeOutExpo; } }

@media screen and (min-width: 1024px) {
  .p-loader .loader__body .txt {
    @include font-size(180); }

  .p-loader .loader__body .anim--02 {
    padding: 20%; }

  // 最初
  .fp-viewing-1 .header,
  .fp-viewing-2 .header,
  .fp-viewing-3 .header,
  .fp-viewing-4 .header,
  .fp-viewing-5 .header,
  .fp-viewing-6 .header,
  .fp-viewing-7 .header,
  .fp-viewing-8 .header,
  .fp-viewing-9 .header,
  .fp-viewing-10 .header,
  .fp-viewing-11 .header, {
    transform: translateY(0%);
    transition: transform 0.5s $easeOutExpo; } }
