@import "../../../../node_modules/sass-easing/_easings.scss";
@import '../helpers/_mixins';

// m-media
.m-media__body {
  @include font-size(14);
  margin-top: 10px; }

.m-media__list {
  margin-top: 10px; }

.m-media__notice {
  margin-top: 5px;
  @include font-size(16); }

.m-media__image {
  margin-top: 15px; }

@media screen and (min-width: 1024px) {
  .m-media {
    display: flex;
    justify-content: space-between;
    width: 50%;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto; }

  .m-media__body {
    padding-right: 30px;
    width: 50%; }

  .m-media__image {
    width: 50%; } }


@media screen and (min-width: 1400px) {
  .m-media {
    width: 80%; } }
